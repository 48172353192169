export enum Direction {
  LEFT = "LEFT",
  TOP_LEFT = "TOP_LEFT",
  BOTTOM_LEFT = "BOTTOM_LEFT",
  RIGHT = "RIGHT",
  TOP_RIGHT = "TOP_RIGHT",
  BOTTOM_RIGHT = "BOTTOM_RIGHT",
  TOP = "TOP",
  BOTTOM = "BOTTOM",
}

export const Directions: Direction[] = [
  Direction.LEFT,
  Direction.TOP_LEFT,
  Direction.BOTTOM_LEFT,
  Direction.RIGHT,
  Direction.TOP_RIGHT,
  Direction.BOTTOM_RIGHT,
  Direction.TOP,
  Direction.BOTTOM,
];

export const POSTBOX_TEMPLATES = ["the_base_postbox"];

export const FLOOR_INFO_TEMPLATES = ["the_base_floorinfo"];

export const THE_BASE_LETTERS = ["A", "B", "C", "D"];

export const MESSAGE_TEMPLATES = [
  "FoodDeliveryArrived",
  "PackageDeliveryArrived",
  "EmployeeVisitorArrived",
];

export const CUSTOMIZABLE_RECEPTION_TEMPLATES = ["digitalreception", "hydro"];

export const MULTITENANT_CLIENTS = ["hydro"];

export enum UserRolesPriority {
  ADMIN = 0,
  CLIENT_ADMIN = 1,
  CLIENT = 2,
  USER = 3,
  TORO1 = 1, // @todo Check this priority with Aca or Pedja
  ACTIVITY = 4,
}
