import React, { useCallback, useEffect, useMemo } from "react";

import * as yup from "yup";
import { toast } from "react-toastify";
import { useToggle } from "react-use";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { FormProvider, useForm } from "react-hook-form";
import { useParams, useHistory, Link } from "react-router-dom";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import Table from "../../../../../../components/Table";
import Input from "../../../../../../components/Input";
import TextArea from "../../../../../../components/Textarea";

import { QUERY_DIGITAL_RECEPTION_MANUAL_REGISTRATION } from "../../../../../../config/graphql/query";
import {
  MUTATION_CREATE_DIGITAL_RECEPTION_MANUAL_REGISTRATION,
  MUTATION_DELETE_DIGITAL_RECEPTION_MANUAL_REGISTRATION,
  MUTATION_DELETE_DIGITAL_RECEPTION_MANUAL_REGISTRATION_FIELD,
  MUTATION_UPDATE_DIGITAL_RECEPTION_MANUAL_REGISTRATION,
} from "../../../../../../config/graphql/mutation";

const TableRow = ({
  item,
  screenId,
  manualRegistrationId,
}: {
  item: IManualRegistrationField;
  screenId: string;
  manualRegistrationId: string;
}) => {
  const { id, type, label, required, code } = item;

  const [visible, setVisible] = useToggle(false);

  const { t } = useTranslation(["manualRegistration", "common"]);

  const [onDelete] = useMutation(
    MUTATION_DELETE_DIGITAL_RECEPTION_MANUAL_REGISTRATION_FIELD,
    {
      refetchQueries: [
        {
          query: QUERY_DIGITAL_RECEPTION_MANUAL_REGISTRATION,
          variables: { id: manualRegistrationId },
        },
      ],
      variables: { id },
    },
  );

  const onBeforeDelete = () => {
    onDelete();

    return setVisible(false);
  };

  return (
    <>
      <tr>
        <th scope="row">{type}</th>
        <td>{label}</td>
        <td>{required.toString()}</td>
        <td className="text-nowrap">
          {code !== "fullName" && (
            <div className="d-flex">
              <Button
                size="sm"
                variant="danger"
                className="mr-2"
                onClick={() => setVisible(true)}
              >
                {t("common:delete")}
              </Button>

              <Link
                to={`/digital-receptions/${screenId}/settings/manual-registrations/${manualRegistrationId}/fields/${id}`}
              >
                <Button size="sm" variant="primary">
                  {t("common:view")}
                </Button>
              </Link>
            </div>
          )}
        </td>
      </tr>
      <Modal
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        contentClassName="p-2"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">
            {t("manualRegistration:manualRegistrationFields.modal.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("manualRegistration:manualRegistrationFields.modal.body", {
            title: item.label,
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setVisible(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onBeforeDelete}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

interface FieldValues {
  title: string;
  description?: string;
}

const ManualRegistration = React.memo(() => {
  const [visible, setVisible] = useToggle(false);

  const history = useHistory();

  const { t } = useTranslation(["manualRegistration", "common"]);

  const { screenId, manualRegistrationId } = useParams<{
    screenId: string;
    manualRegistrationId?: string;
  }>();

  const schema = useMemo(() => {
    return yup.object().shape({
      title: yup
        .string()
        .required(t("manualRegistration:manualRegistration.yup.title")),
    });
  }, [t]);

  const methods = useForm<FieldValues>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    defaultValues: {
      title: "",
      description: undefined,
    },
  });

  const { data: manualRegistrationData, loading } = useQuery<{
    manualRegistration: IManualRegistration;
  }>(QUERY_DIGITAL_RECEPTION_MANUAL_REGISTRATION, {
    variables: {
      id: manualRegistrationId,
    },
    skip: !screenId,
  });

  const manualRegistrationFields = useMemo(
    () => manualRegistrationData?.manualRegistration?.fields ?? [],
    [manualRegistrationData],
  );

  useEffect(() => {
    if (manualRegistrationData?.manualRegistration) {
      methods.reset(manualRegistrationData?.manualRegistration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manualRegistrationData]);

  const [onUpdate] = useMutation(
    MUTATION_UPDATE_DIGITAL_RECEPTION_MANUAL_REGISTRATION,
    {
      refetchQueries: [
        {
          query: QUERY_DIGITAL_RECEPTION_MANUAL_REGISTRATION,
          variables: { id: manualRegistrationId },
        },
      ],
    },
  );
  const [onCreate] = useMutation(
    MUTATION_CREATE_DIGITAL_RECEPTION_MANUAL_REGISTRATION,
    {
      refetchQueries: [
        {
          query: QUERY_DIGITAL_RECEPTION_MANUAL_REGISTRATION,
          variables: { id: manualRegistrationId },
        },
      ],
    },
  );
  const [onDelete] = useMutation(
    MUTATION_DELETE_DIGITAL_RECEPTION_MANUAL_REGISTRATION,
    {
      refetchQueries: [
        {
          query: QUERY_DIGITAL_RECEPTION_MANUAL_REGISTRATION,
          variables: { id: manualRegistrationId },
        },
      ],
    },
  );

  const onBeforeDelete = useCallback(() => {
    return onDelete({
      variables: {
        id: manualRegistrationId,
      },
    })
      .then(() => {
        history.replace(
          `/digital-receptions/${screenId}/settings/manual-registrations`,
        );
        toast.success<string>(
          t("manualRegistration:manualRegistration.toast.deleted"),
        );
      })
      .catch(() => {
        toast.error<string>(
          t("manualRegistration:manualRegistration.toast.deletedError"),
        );
      });
  }, [history, onDelete, manualRegistrationId, screenId, t]);

  const onSubmit = useCallback(
    (values: FieldValues) => {
      const { title, description } = values;

      const input = {
        title,
        ...(typeof description === "string" && { description }),
      };

      if (!!manualRegistrationId) {
        return onUpdate({
          variables: {
            input: {
              id: manualRegistrationId,
              ...input,
            },
          },
        })
          .then(() => {
            toast.success<string>(
              t("manualRegistration:manualRegistration.toast.updated"),
            );
          })
          .catch(() => {
            toast.error<string>(
              t("manualRegistration:manualRegistration.toast.updatedError"),
            );
          });
      }

      return onCreate({
        variables: {
          input: {
            ...input,
            screen: screenId,
          },
        },
      })
        .then(
          ({
            data: {
              createManualRegistration: { id: manualRegistrationId },
            },
          }) => {
            toast.success<string>(
              t("manualRegistration:manualRegistration.toast.created"),
            );

            history.replace(
              `/digital-receptions/${screenId}/settings/manual-registrations/${manualRegistrationId}`,
            );
          },
        )
        .catch(() => {
          toast.error<string>(
            t("manualRegistration:manualRegistration.toast.createdError"),
          );
        });
    },
    [history, screenId, onUpdate, onCreate, manualRegistrationId, t],
  );

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to="/digital-receptions">
              {t("screens:screensRouter.nav.screens")}
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/digital-receptions/${screenId}`}>
              {t("screens:screen.screenRoute.nav.screen")}
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/digital-receptions/${screenId}/settings`}>
              {t("screens:screen.screenRoute.nav.settings")}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Link
              to={`/digital-receptions/${screenId}/settings/manual-registrations`}
            >
              {t("screens:screen.screenRoute.nav.manualRegistrations")}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {t("screens:screen.screenRoute.nav.manualRegistration")}
          </li>
        </ol>
      </nav>
      <FormProvider {...methods}>
        <form className="row mt-4" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="form-group">
              <label htmlFor="title">
                {t("manualRegistration:manualRegistration.form.title")}
              </label>
              <Input name="title" className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="description">
                {t("manualRegistration:manualRegistration.form.description")}
              </label>
              <TextArea name="description" className="form-control" />
            </div>
            <div className="mb-4">
              <button
                type="submit"
                className="btn btn-primary mt-4"
                disabled={loading}
              >
                {t(
                  `manualRegistration:manualRegistration.form.button.${
                    !manualRegistrationId ? "create" : "update"
                  }`,
                )}
              </button>
              {!!manualRegistrationId && (
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();

                    setVisible(true);
                  }}
                  className="btn btn-danger ml-3 mt-4"
                >
                  {t("common:delete")}
                </button>
              )}
            </div>
          </div>
          {!!manualRegistrationId && (
            <div className="col-lg-9 col-md-6 col-sm-12">
              <div className="d-flex justify-content-between align-items-center">
                <p>
                  {t("manualRegistration:manualRegistrationFields.title", {
                    count: 2,
                  })}
                </p>
                <Link
                  to={`/digital-receptions/${screenId}/settings/manual-registrations/${manualRegistrationId}/fields/new`}
                  type="button"
                  className="btn btn-primary"
                  style={{ marginTop: "-1.5rem" }}
                >
                  {t("manualRegistration:manualRegistrationFields.button.add")}
                </Link>
              </div>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th scope="col">
                      {t(
                        "manualRegistration:manualRegistrationFields.table.th.type",
                      )}
                    </th>
                    <th scope="col">
                      {t(
                        "manualRegistration:manualRegistrationFields.table.th.label",
                      )}
                    </th>

                    <th scope="col">
                      {t(
                        "manualRegistration:manualRegistrationFields.table.th.required",
                      )}
                    </th>
                    <th scope="col">
                      {t(
                        "manualRegistration:manualRegistrationFields.table.th.actions",
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!!manualRegistrationFields.length &&
                    manualRegistrationFields.map((item) => (
                      <TableRow
                        key={item.id}
                        item={item}
                        screenId={screenId}
                        manualRegistrationId={manualRegistrationId}
                      />
                    ))}
                  {!manualRegistrationFields.length && (
                    <tr>
                      <th colSpan={5} scope="row" className="text-center">
                        {t(
                          "manualRegistration:manualRegistrationFields.table.noFields",
                        )}
                      </th>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          )}
        </form>
      </FormProvider>
      <Modal
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        contentClassName="p-2"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">
            {t("manualRegistration:manualRegistration.modal.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("manualRegistration:manualRegistration.modal.body")}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setVisible(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onBeforeDelete}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default ManualRegistration;
