import React, { useEffect, useMemo, useState } from "react";

import * as yup from "yup";
import get from "lodash/get";
import map from "lodash/map";
import find from "lodash/find";
import clsx from "clsx";
import Select from "react-select";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useParams, useHistory, Link } from "react-router-dom";

import {
  MUTATION_CREATE_DIGITAL_RECEPTION_MANUAL_REGISTRATION_FIELD,
  MUTATION_UPDATE_DIGITAL_RECEPTION_MANUAL_REGISTRATION_FIELD,
} from "../../../../../../config/graphql/mutation";

import {
  QUERY_DIGITAL_RECEPTION_ACTIVE_LANGUAGES,
  QUERY_DIGITAL_RECEPTION_MANUAL_REGISTRATION,
  QUERY_DIGITAL_RECEPTION_MANUAL_REGISTRATION_FIELD,
  QUERY_DIGITAL_RECEPTION_SETTINGS,
} from "../../../../../../config/graphql/query";

import { reactSelectCustomStyles } from "../../../../../Employees/Employee/Information";

const fieldTypeOptions: ManualRegistrationFieldType[] = [
  "Text",
  // "Textarea",
  "Email",
  "Phone",
  // "Date",
  "Number",
  // "List",
  "EmployeeList",
];

interface FieldValues {
  type: ManualRegistrationFieldType;
  label: string;
  description?: string;
  required: boolean;
  manualCheckoutIdentifier?: boolean;
}

const ManualRegistrationField = React.memo(() => {
  const history = useHistory();

  const { t } = useTranslation(["manualRegistration", "common"]);

  const [language, setLanguage] = useState<string>();

  const { screenId, manualRegistrationId, manualRegistrationFieldId } =
    useParams<{
      screenId?: string;
      manualRegistrationId?: string;
      manualRegistrationFieldId?: string;
    }>();

  const schema = useMemo(() => {
    return yup.object().shape({
      type: yup
        .string()
        .oneOf(fieldTypeOptions)
        .required(t("manualRegistration:manualRegistrationField.yup.type")),
      label: yup
        .string()
        .required(t("manualRegistration:manualRegistrationField.yup.label")),
    });
  }, [t]);

  const methods = useForm<FieldValues>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    defaultValues: {
      type: "Text",
      label: "",
      description: undefined,
      required: true,
      manualCheckoutIdentifier: undefined,
    },
  });

  const { data: screenData } = useQuery<{
    digitalReception: IDigitalReception;
  }>(QUERY_DIGITAL_RECEPTION_ACTIVE_LANGUAGES, {
    skip: !screenId,
    variables: {
      id: screenId,
    },
    onCompleted: ({ digitalReception }) => {
      setLanguage(
        language && digitalReception.languages.includes(language)
          ? language
          : digitalReception.languages[0],
      );
    },
  });

  const languageOptions = useMemo(
    () =>
      map(screenData?.digitalReception.languages, (value) => ({
        value,
        label: t(`languages:${value}`),
      })) ?? [],
    [screenData, t],
  );

  const {
    data: manualRegistrationFieldData,
    loading,
    refetch,
  } = useQuery<{
    manualRegistrationField: IManualRegistrationField;
  }>(QUERY_DIGITAL_RECEPTION_MANUAL_REGISTRATION_FIELD, {
    variables: {
      id: manualRegistrationFieldId,
      language,
    },
    skip: !manualRegistrationFieldId || !language,
    onError: () => {
      history.replace(
        `/digital-receptions/${screenId}/settings/manual-registrations/${manualRegistrationId}`,
      );
    },
  });

  useEffect(() => {
    if (!!manualRegistrationFieldId && !!language) {
      refetch({ id: manualRegistrationFieldId, language });
    }
  }, [language, refetch, manualRegistrationFieldId]);

  useEffect(() => {
    if (manualRegistrationFieldData?.manualRegistrationField) {
      methods.reset(manualRegistrationFieldData?.manualRegistrationField);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manualRegistrationFieldData]);

  const [onUpdate] = useMutation(
    MUTATION_UPDATE_DIGITAL_RECEPTION_MANUAL_REGISTRATION_FIELD,
    {
      refetchQueries: [
        {
          query: QUERY_DIGITAL_RECEPTION_MANUAL_REGISTRATION_FIELD,
          variables: { id: manualRegistrationFieldId, language },
        },
        {
          query: QUERY_DIGITAL_RECEPTION_SETTINGS,
          variables: { id: screenId, language },
        },
      ],
    },
  );
  const [onCreate] = useMutation(
    MUTATION_CREATE_DIGITAL_RECEPTION_MANUAL_REGISTRATION_FIELD,
    {
      refetchQueries: [
        {
          query: QUERY_DIGITAL_RECEPTION_MANUAL_REGISTRATION,
          variables: { id: manualRegistrationId, language },
        },
        {
          query: QUERY_DIGITAL_RECEPTION_SETTINGS,
          variables: { id: screenId, language },
        },
      ],
    },
  );

  const fieldSelectOptions = useMemo(
    () =>
      map(fieldTypeOptions, (type) => ({
        value: type,
        label: type,
      })),
    [],
  );

  const onSubmit = async (values: FieldValues) => {
    const { type, label, description, required, manualCheckoutIdentifier } =
      values;

    const input = {
      type,
      label,
      ...(typeof description === "string" && { description }),
      required,
      manualCheckoutIdentifier,
    };

    if (!!manualRegistrationFieldId) {
      return onUpdate({
        variables: {
          input: {
            id: manualRegistrationFieldId,
            language,
            ...input,
          },
        },
      })
        .then(() => {
          toast.success<string>(
            t("manualRegistration:manualRegistrationField.toast.updated"),
          );

          // history.replace(
          //   `/digital-receptions/${screenId}/settings/manual-registrations/${manualRegistrationId}`,
          // );
        })
        .catch((error) => {
          toast.error<string>(
            t(
              error?.networkError?.result?.errors?.[0]?.message ??
                error?.message,
            ),
          );
        });
    }

    return onCreate({
      variables: {
        input: {
          manualRegistration: manualRegistrationId,
          screen: screenId,
          language,
          ...input,
        },
      },
    })
      .then(() => {
        toast.success<string>(
          t("manualRegistration:manualRegistrationField.toast.created"),
        );

        // history.replace(
        //   `/digital-receptions/${screenId}/settings/manual-registrations/${manualRegistrationId}`,
        // );
      })
      .catch((error) => {
        toast.error<string>(
          t(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          ),
        );
      });
  };

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to="/digital-receptions">
              {t("screens:screensRouter.nav.screens")}
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/digital-receptions/${screenId}`}>
              {t("screens:screen.screenRoute.nav.screen")}
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/digital-receptions/${screenId}/settings`}>
              {t("screens:screen.screenRoute.nav.settings")}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Link
              to={`/digital-receptions/${screenId}/settings/manual-registrations`}
            >
              {t("screens:screen.screenRoute.nav.manualRegistrations")}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Link
              to={`/digital-receptions/${screenId}/settings/manual-registrations/${manualRegistrationId}`}
            >
              {t("screens:screen.screenRoute.nav.manualRegistration")}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {t("screens:screen.screenRoute.nav.manualRegistrationField")}
          </li>
        </ol>
      </nav>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <label htmlFor="roles" className="mb-2">
                {t("screens:screen.settings.layout.language")}
              </label>
              <div className="mb-4">
                <Select
                  options={languageOptions}
                  value={languageOptions.find(
                    (option) => option.value === language,
                  )}
                  onChange={(value) => {
                    setLanguage(value?.value);
                  }}
                  styles={reactSelectCustomStyles(false)}
                />
              </div>
              <Controller
                name="type"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <div className="form-group">
                      <label htmlFor="type">
                        {t(
                          "manualRegistration:manualRegistrationField.form.type",
                        )}
                      </label>
                      <Select
                        options={fieldSelectOptions}
                        onChange={(nextValue) => {
                          const nextField = get(nextValue, "value");
                          onChange(nextField);
                        }}
                        value={find(fieldSelectOptions, { value })}
                        className={clsx({
                          "is-invalid": !!error,
                        })}
                      />
                      {!!error && (
                        <div className="invalid-feedback">{error.message}</div>
                      )}
                    </div>
                  );
                }}
              />
              <Controller
                name="manualCheckoutIdentifier"
                render={({ field: { value, name, onChange, ...rest } }) => {
                  return (
                    <div className="form-check my-3">
                      <input
                        id={name}
                        {...rest}
                        type="checkbox"
                        className="form-check-input"
                        checked={value === true}
                        onChange={() => onChange(!value)}
                      />
                      <label className="form-check-label" htmlFor={name}>
                        {t(
                          "manualRegistration:manualRegistrationField.form.manualCheckoutIdentifier",
                        )}
                      </label>
                    </div>
                  );
                }}
              />
              <Controller
                name="label"
                render={({
                  field: { onChange, ...props },
                  fieldState: { error },
                }) => (
                  <div className="form-group">
                    <label htmlFor="label">
                      {t(
                        "manualRegistration:manualRegistrationField.form.label",
                      )}
                    </label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          {language?.toUpperCase() ?? "en"}
                        </span>
                      </div>
                      <input
                        onChange={(value) => {
                          onChange(value);
                        }}
                        {...props}
                        className={clsx("form-control", {
                          "is-invalid": !!error,
                        })}
                      />
                      {!!error && (
                        <div className="invalid-feedback">{error?.message}</div>
                      )}
                    </div>
                  </div>
                )}
              />

              <Controller
                name="description"
                render={({
                  field: { onChange, ...props },
                  fieldState: { error },
                }) => (
                  <div className="form-group">
                    <label htmlFor="description">
                      {t(
                        "manualRegistration:manualRegistrationField.form.description",
                      )}
                    </label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          {language?.toUpperCase() ?? "en"}
                        </span>
                      </div>
                      <input
                        onChange={(value) => {
                          onChange(value);
                        }}
                        {...props}
                        className={clsx("form-control", {
                          "is-invalid": !!error,
                        })}
                      />
                      {!!error && (
                        <div className="invalid-feedback">{error?.message}</div>
                      )}
                    </div>
                  </div>
                )}
              />

              <Controller
                name="required"
                render={({ field: { value, name, onChange, ...rest } }) => {
                  return (
                    <div className="form-check my-3">
                      <input
                        id={name}
                        {...rest}
                        type="checkbox"
                        className="form-check-input"
                        checked={value === true}
                        onChange={() => onChange(!value)}
                      />
                      <label className="form-check-label" htmlFor={name}>
                        {t(
                          "manualRegistration:manualRegistrationField.form.required",
                        )}
                      </label>
                    </div>
                  );
                }}
              />
              <button
                type="submit"
                className="btn btn-primary mt-3"
                disabled={loading}
              >
                {t(
                  `manualRegistration:manualRegistrationField.form.button.${
                    !manualRegistrationFieldId ? "create" : "update"
                  }`,
                )}
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
});

export default ManualRegistrationField;
